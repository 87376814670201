type SubMenuLink = {
  name: string;
  url: string;
  key: string;
};

type NavigationLink = {
  name: string;
  url: string;
  key: string;
  isExpanded?: boolean;
  links?: SubMenuLink[];
};

export function getNavigationLinks(opts: {
  canViewTimesheet: boolean | undefined;
  canViewOpportunity: boolean | undefined;
  canViewFinancialAll: boolean | undefined;
  canManageSkills: boolean | undefined;
  canManageCourses: boolean | undefined;
}) {
  const links: NavigationLink[] = [
    {
      name: 'Home',
      url: '/home',
      key: 'home',
    },
    {
      name: 'Clients',
      url: '/clients',
      key: 'clients',
    },
  ];
  if (opts?.canViewTimesheet) {
    links.push({
      name: 'Timesheet',
      url: '/timesheet',
      key: 'timesheet',
    });
  }

  links.push({
    name: 'Bookings Timeline',
    url: '/bookingstimeline',
    key: 'bookingstimeline',
  });

  if (opts?.canViewOpportunity) {
    links.push({
      name: 'Opportunities',
      url: '/opportunities',
      key: 'opportunities',
    });
  }

  links.push({
    name: 'Jobs',
    url: '/jobs',
    key: 'jobs',
  });

  if (false) {
    links.push({
      name: 'Invoices',
      url: '/invoices',
      key: 'invoices',
    });
  }

  const skillsSubLinks = [
    {
      name: 'Skills',
      url: '/skills',
      key: 'skills',
    },
    {
      name: 'Courses',
      url: '/courses',
      key: 'courses',
    },
  ];

  if (opts?.canManageSkills) {
    skillsSubLinks.push(
      {
        name: 'Skill Priorities',
        url: '/skillpriorities',
        key: 'skillpriorities',
      },
      {
        name: 'Course Priorities',
        url: '/coursepriorities',
        key: 'coursepriorities',
      },
      {
        name: 'Manage Skills',
        url: '/manageskills',
        key: 'manageskills',
      },
      {
        name: 'Manage Courses',
        url: '/managecourses',
        key: 'managecourses',
      }
    );
  }

  links.push({
    name: 'Training Zone',
    url: '',
    key: 'trainingzone',
    isExpanded: false,
    links: skillsSubLinks,
  });

  return links;
}
